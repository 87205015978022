<!--
 * @Descripttion:pdf展示弹窗 
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-17 12:04:41
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-03-27 22:08:10
-->
<template>
    <div class="PdfModal">
        <Modal v-model="modalFlag" draggable scrollable title="文件展示" footer-hide :width="1200" class="PdfMod">
            <pdf 
            :src="url"
            @num-pages="pageNums($event)"
            v-show="false"
            ></pdf>
            <pdf v-for="(page,index) in totalPage" :key="index" :src="url" :page="(index+1)"></pdf>
        </Modal>
    </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
  name:"PdfModal",
  data(){
      return {
          "modalFlag":false,
          "url":"",
          "totalPage":0
      }
  },
  props:{
      
  },
  components:{
      "pdf":pdf
  },
  methods:{
      //先查询
      open(url){
        this.$set(this,'url',url);
        this.$nextTick(()=>{
            this.$set(this,"modalFlag",true);
        })
      },
      close(){
        this.$set(this,"modalFlag",false);
        this.$set(this,'totalPage',0);
      },
      pageNums(e){
          if(e){
              this.$set(this,'totalPage',e);
          }
      }
  }
}
</script>
<style lang="less">
.PdfMod .ivu-modal-body{
    height:700px;
    overflow-y:auto;
}
</style>
